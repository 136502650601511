var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as S from './index.styles';
var ProgressBar = function (_a) {
    var percentage = _a.percentage, color = _a.color;
    return (_jsx(S.ProgressBarBackground, { children: _jsx(S.ProgressBarFill, __assign({ percentage: percentage, color: color }, { children: _jsxs(S.ProgressBarText, { children: [percentage, "%"] }) })) }));
};
export default ProgressBar;
