import { useSelector } from 'react-redux';
import { useActionLoader } from '../../../../hooks/useActionLoader';
import { fetch98976CurrentPeriodSummary } from '../../../../state/reducers/billing';
import * as S from './index.styles';
import { RootState } from '../../../../state/store';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { UserRoles } from '../../../../types/models';

export enum DayCountColors {
    RED = 'RED',
    YELLOW = 'YELLOW',
    GREEN = 'GREEN'
}

const Current98976Summary = ({ expanded }: {expanded: boolean }) => {
    const { patientId } = useParams();
    const { user: provider } = useSelector((store: RootState) => store.session);
    const [isPT, setIsPT] = useState(false);
    const { callAction: get98976Summary, done: current98976SummaryDone, loading: current98976SummaryLoading } = useActionLoader(fetch98976CurrentPeriodSummary);
    const { current98976PeriodSummary } = useSelector((state: RootState) => state.billing);

    const getDayCountColor = () => {
        if (current98976PeriodSummary.daysWith98976Data >= 16) {
            return DayCountColors.GREEN;
        } else if (current98976PeriodSummary.daysWith98976Data >= 10) {
            return DayCountColors.YELLOW;
        }
        return DayCountColors.RED;
    };

    const getDaysAwayText = (daysAway: number) => {
        return daysAway > 1 ? 'Days of activity away' : 'Day of activity away';
    };

    const getDaysUntilNewPeriodText = (daysUntilNewPeriod: number) => {
        return daysUntilNewPeriod > 1 ? 'Days away from the start of a new period' : 'Day away from the start of a new period';
    };

    useEffect(() => {
        if (provider?.roles.find(role => role.billable)?.role === UserRoles.PHYSICAL_THERAPIST) {
            setIsPT(true);
        }
    }, [provider]);

    useEffect(() => {
        if (!current98976SummaryDone && !current98976SummaryLoading) {
            get98976Summary(patientId);
        }
    }, [patientId, current98976SummaryDone, current98976SummaryLoading]);

    return (
        <S.SummaryContainer expanded={expanded}>
            <S.Current98976Summary>
                <S.DaysCount color={getDayCountColor()}>
                    {current98976PeriodSummary.daysWith98976Data >= 16
                        && <S.DaysCountText bold>{current98976PeriodSummary.daysWith98976Data}/30 Goal Reached</S.DaysCountText>}
                    {current98976PeriodSummary.daysWith98976Data < 16
                        && <S.DaysCountText><b>{current98976PeriodSummary.daysWith98976Data}/30</b> Days</S.DaysCountText>}
                </S.DaysCount>
                <S.DaysAway>
                    {current98976PeriodSummary.daysWith98976Data >= 16
                        && <S.DaysAwayText><b>{current98976PeriodSummary.daysLeftInPeriod}</b> {getDaysUntilNewPeriodText(current98976PeriodSummary.daysLeftInPeriod)}</S.DaysAwayText>}
                    {current98976PeriodSummary.daysWith98976Data < 16
                        && <S.DaysAwayText><b>{16 - current98976PeriodSummary.daysWith98976Data}</b> {getDaysAwayText(16 - current98976PeriodSummary.daysWith98976Data)}</S.DaysAwayText>}
                </S.DaysAway>
            </S.Current98976Summary>
        </S.SummaryContainer>
    );
};

export default Current98976Summary;
