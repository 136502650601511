import { Buttons, Inputs, useConfirmDialog, VideoEmbed, Spinner } from '@apps/common-ui';
import { LearningTypes, slugify } from '@apps/common-utilities';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Routes } from '../../api/Routes';
import { PageHeader } from '../../components/common/commonStyled';
import LessonContentInput from '../../components/LessonContentInput';
import { RootState } from '../../state/store';
import * as S from './index.styles';
import { getWordCountFromHtml } from './utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { isVimeoUrl } from '../../utils/url';
import { TenantApi } from '../../api/CoachingAPI';

type RouteParams = {
    lessonId?: string;
    moduleId: string;
    courseId: string;
    moduleName: string;
}

const ManageLesson = () => {
    const { register, control, watch, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
    const { courses } = useSelector((state: RootState) => state.learning);
    const { lessonId, courseId, moduleId, moduleName } = useParams<RouteParams>();
    const [course, setCourse] = useState<LearningTypes.ICourse>();
    const [lesson, setLesson] = useState<LearningTypes.ILesson>();
    const [loading, setLoading] = useState(true);
    const [wordCount, setWordCount] = useState(0);
    const readableModuleName = moduleName && slugify.fromSlug(moduleName);
    const navigate = useNavigate();
    const [previewing, setPreviewing] = useState(false);

    const fetchLesson = async (lessonIdToGet: string) => {
        const fetchedLesson = await TenantApi.get(`/${Routes.coaches}/${Routes.courses}/lessons/${lessonIdToGet}`);
        setLesson(fetchedLesson);
        setValue('title', fetchedLesson.title);
        setValue('content', fetchedLesson.content);
        setValue('videoUrl', fetchedLesson.videoUrl);
        setWordCount(getWordCountFromHtml(fetchedLesson.content));
        setLoading(false);
    };

    useEffect(() => {
        if (courseId) {
            const selectedCourse = courses.find((c) => c.courseId === Number(courseId));
            setCourse(selectedCourse);
        }
        if (lessonId) {
            fetchLesson(lessonId);
        } else {
            setLoading(false);
        }
    }, [courseId, lessonId, courses]);

    useEffect(() => {
        if (watch('content')) {
            setWordCount(getWordCountFromHtml(watch('content')));
        }
    }, [watch('content')]);

    const { ConfirmationDialog, confirm } = useConfirmDialog({
        message: 'Are you sure you want to delete this lesson?',
        cancelType: 'danger'
    });

    const confirmDelete = (e: any) => {
        e.preventDefault();
        confirm().then((result) => {
            if (result) {
                TenantApi.delete(`/${Routes.coaches}/${Routes.courses}/${courseId}/modules/${moduleId}/lessons/${lessonId}`).then(res => {
                    toast.success('Lesson deleted successfully');
                    navigate(-1);
                });
            }
        });
    };
    // For some reason the camera emoji is being added when saving a lesson with images in the content...
    const removeCameraIcon = (content: string) => content.replaceAll('📷', '');

    const onUpdateLesson = (data: any) => {
        const lessonPayload: LearningTypes.ILesson = {
            ...lesson,
            ...data
        };
        lessonPayload.content = removeCameraIcon(lessonPayload.content);
        TenantApi.put(`/${Routes.coaches}/${Routes.courses}/${courseId}/modules/${moduleId}/lessons/${lessonId}`, lessonPayload).then(res => {
            toast.success('Lesson saved successfully');
            navigate(-1);
        });
    };

    const onCreateLesson = (data: any) => {
        const payload: LearningTypes.ILesson = {
            ...data,
            courseId: Number(courseId),
            moduleId: Number(moduleId),
        };
        payload.content = removeCameraIcon(payload.content);
        TenantApi.post(`/${Routes.coaches}/${Routes.courses}/${courseId}/modules/${moduleId}/lessons`, payload).then(res => {
            toast.success('Lesson saved successfully');
            navigate(-1);
        });
    };

    const onSave = (data: any) => {
        if (lessonId) {
            onUpdateLesson(data);
        } else {
            onCreateLesson(data);
        }
    };

    const togglePreviewing = (e: any) => {
        e.preventDefault();
        setPreviewing(!previewing);
    };

    const cancelEdit = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    if (loading) {
        return <Spinner />;
    }

    if (previewing) {
        const contentWithCameraIcon = watch('content');
        const formattedContent = removeCameraIcon(contentWithCameraIcon);
        return (
            <S.LessonPreviewContainer>
                <Buttons.Button buttonType="tertiary" onClick={togglePreviewing}>
                    <FontAwesomeIcon icon={faArrowLeft as IconProp} />
                    Back
                </Buttons.Button>
                <h1>{watch('title')}</h1>
                <S.LessonPreviewContent dangerouslySetInnerHTML={{ __html: formattedContent }} />
            </S.LessonPreviewContainer>
        );
    }

    return (
        <S.LessonForm onSubmit={handleSubmit(onSave)}>
            <ConfirmationDialog />
            <PageHeader>
                <S.Titles>
                    <h2>Manage Lesson</h2>
                </S.Titles>
                {lessonId && <Buttons.Button onClick={confirmDelete} buttonStyle="danger" buttonType="tertiary">Delete Lesson</Buttons.Button>}
            </PageHeader>
            <Inputs.InputContainer error={errors.title}>
                <Inputs.Label>Lesson Name</Inputs.Label>
                <Inputs.Input
                  id="title"
                  placeholder="Lesson Name"
                  inputSize={Inputs.InputSize.fullWidth}
                  {...register('title', { required: true })}
                />
            </Inputs.InputContainer>
            <S.FormColumns>
                <S.FormColumn>
                    <Inputs.InputContainer>
                        <Inputs.Label>Module</Inputs.Label>
                        <S.ModuleName>{readableModuleName}</S.ModuleName>
                    </Inputs.InputContainer>
                    <Inputs.InputContainer>
                        <Inputs.Label>Lesson Video URL</Inputs.Label>
                        <Inputs.Input
                          id="lessonVideoUrl"
                          placeholder="Lesson Video URL"
                          error={errors.videoUrl}
                          {...register('videoUrl', {
                            validate: (u) => (!isVimeoUrl(u as string) && u as string !== '')
                            ? 'Video URL must be a valid Vimeo URL or empty'
                            : true
                          })}

                        />
                    </Inputs.InputContainer>
                    <Inputs.ErrorMessage>{errors.videoUrl?.message}</Inputs.ErrorMessage>
                </S.FormColumn>
                <S.FormColumn>
                    <Inputs.Label>Lesson Video Preview</Inputs.Label>
                    <VideoEmbed
                      title="Lesson Video Preview"
                      src={watch('videoUrl')}
                    />
                </S.FormColumn>
            </S.FormColumns>
            <S.LessonContentContainer>
                <S.LessonContentHeader>
                    <Inputs.Label>Lesson Content</Inputs.Label>
                    <span><S.WordCount isOver={wordCount > 400}>{wordCount} words </S.WordCount>(max of 400 recommended)</span>
                </S.LessonContentHeader>
                <Controller
                  name="content"
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                      <LessonContentInput
                        onChange={onChange}
                        defaultValue={value || lesson?.content}
                      />
                  )}
                />
            </S.LessonContentContainer>
            <S.ButtonsContainer>
                <S.ButtonsGroup>
                    <Buttons.Button type="submit">Save Lesson</Buttons.Button>
                    <Buttons.Button buttonType="tertiary" onClick={cancelEdit}>Cancel</Buttons.Button>
                </S.ButtonsGroup>
                <Buttons.Button buttonType="tertiary" onClick={togglePreviewing}>Preview</Buttons.Button>
            </S.ButtonsContainer>
        </S.LessonForm>
    );
};

export default ManageLesson;
