import { Modal } from '@apps/common-ui';
import React, { useEffect, useState } from 'react';
import { TimerState } from '../CareTeamTimer/useTimerStateMachine';
import * as S from './index.styles';

interface Props {
    showModal: boolean;
    dismissModal: () => void;
    continueClicked: () => void;
    stopClicked: () => void;
    popupInterval: number;
    stopTimerInterval: number;
    stopTimer: () => void;
    timerValue: string;
    shouldClearTimeout: boolean;
    state: TimerState;
}

export const TimerPopup = ({ showModal, dismissModal, continueClicked, stopClicked, popupInterval, stopTimerInterval, stopTimer, timerValue, shouldClearTimeout, state }: Props) => {
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);
    const [prevTimerValue, setPrevTimerValue] = useState('00:00');

    useEffect(() => {
        if (showModal) {
            // when modal opens, start timeout that will stop the timer after the stopTimerInterval has passed
            if (timer) {
                clearTimeout(timer);
            }
            setTimer(setTimeout(() => {
                stopTimer();
            }, stopTimerInterval * 1000));
        }
    }, [showModal]);

    // if shouldClearTimeout is updated to true, clear the timeout
    useEffect(() => {
        if (shouldClearTimeout) {
            if (timer) {
                clearTimeout(timer);
            }
        }
    }, [shouldClearTimeout]);

    useEffect(() => {
        if (timerValue !== '00:00') {
            setPrevTimerValue(timerValue);
        }
    }, [timerValue]);

    return (
        <Modal showModal={showModal} dismissModal={dismissModal} showTopBar={false} closeOnOutsideClick={false} minWidth="500px">
            <S.Container>
                <S.Title>Are you still there?</S.Title>
                <S.TimerValue isStopped={state !== TimerState.RUNNING}>{state === TimerState.RUNNING ? timerValue : prevTimerValue}</S.TimerValue>
                <S.ButtonContainer>
                    <S.ContinueButton
                      onClick={() => {
                            if (timer) {
                                clearTimeout(timer);
                            }
                            continueClicked();
                            dismissModal();
                        }}
                    >
                        Yes, I&apos;m Still Here
                    </S.ContinueButton>
                    <S.StopButton
                      onClick={() => {
                            if (timer) {
                                clearTimeout(timer);
                            }
                            stopClicked();
                            dismissModal();
                        }}
                    >
                        No, Stop Timer
                    </S.StopButton>
                </S.ButtonContainer>
                <S.Message>
                    {`Every ${popupInterval} minutes we check to confirm you are still here. This is to ensure accurate time tracking for asynchronous billing codes.`}
                </S.Message>
            </S.Container>
        </Modal>
    );
};
export default TimerPopup;
