/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { editorEntityStyleHandler, dragAndDropUpload } from './utils';
import { stateToHTML } from 'draft-js-export-html';
import createToolbarPlugin, {
    Separator,
} from '@draft-js-plugins/static-toolbar';
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    HeadlineOneButton,
    HeadlineTwoButton,
    HeadlineThreeButton,
    UnorderedListButton,
    OrderedListButton
} from '@draft-js-plugins/buttons';
import createImagePlugin from '@draft-js-plugins/image';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createFocusPlugin from '@draft-js-plugins/focus';
import createDragNDropUploadPlugin from '@draft-js-plugins/drag-n-drop-upload';
import createResizeablePlugin from '@draft-js-plugins/resizeable';

const createEditor = (plugins?: any[]) => {
    const staticToolbarPlugin = createToolbarPlugin();
    const inlineToolbarPlugin = createInlineToolbarPlugin();
    const blockDndPlugin = createBlockDndPlugin();
    const focusPlugin = createFocusPlugin();
    const resizeablePlugin = createResizeablePlugin();

    const decorator = composeDecorators(
        focusPlugin.decorator,
        blockDndPlugin.decorator,
        resizeablePlugin.decorator
    );

    const imagePlugin = createImagePlugin({ decorator });

    const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
        handleUpload: dragAndDropUpload,
        addImage: imagePlugin.addImage as any,
    });

    const defaultPlugins = [
        dragNDropFileUploadPlugin,
        resizeablePlugin,
        blockDndPlugin,
        focusPlugin,
        staticToolbarPlugin,
        inlineToolbarPlugin,
        imagePlugin,
        resizeablePlugin
    ];

    const { Toolbar } = staticToolbarPlugin;

    return {
        editorPlugins: plugins || defaultPlugins,
        InputToolBar: Toolbar
    };
};

type Props = {
    onChange: (content: string) => void;
    defaultValue?: string;
    plugins?: any[];
}

const RichTextInput = ({ onChange, defaultValue, plugins }: Props) => {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const editor = useRef<Editor>(null);
    const [{ editorPlugins, InputToolBar }] = useState(createEditor(plugins));

    useEffect(() => {
        if (defaultValue) {
            const state = convertFromHTML(defaultValue);
            const contentState = ContentState.createFromBlockArray(
                state.contentBlocks,
                state.entityMap
            );
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
        }
    }, []);

    const focus = () => {
        editor.current?.focus();
    };

    const onChangeHandler = (state: EditorState) => {
        setEditorState(state);
        const html = stateToHTML(state.getCurrentContent(), {
            entityStyleFn: editorEntityStyleHandler
        });
        onChange(html);
    };

    return (
        <div onClick={focus}>
            <InputToolBar>
                {(externalProps: any) => (
                    <>
                        <BoldButton {...externalProps} />
                        <ItalicButton {...externalProps} />
                        <UnderlineButton {...externalProps} />
                        <Separator {...externalProps} />
                        <HeadlineOneButton {...externalProps} />
                        <HeadlineTwoButton {...externalProps} />
                        <HeadlineThreeButton {...externalProps} />
                        <Separator {...externalProps} />
                        <UnorderedListButton {...externalProps} />
                        <OrderedListButton {...externalProps} />
                    </>
                )}
            </InputToolBar>
            <Editor
              editorState={editorState}
              onChange={onChangeHandler}
              plugins={editorPlugins}
              ref={editor}
            />
        </div>
    );
};

export default RichTextInput;
