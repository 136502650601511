import React from 'react';
import * as S from './index.styles';
import RichTextInput from '../RichTextInput';

type Props = {
    onChange: (content: string) => void;
    defaultValue?: string;
}

const LessonContentInput = ({ onChange, defaultValue }: Props) => (
    <S.EditorContainer>
        <RichTextInput
          onChange={onChange}
          defaultValue={defaultValue}
        />
    </S.EditorContainer>
);

export default LessonContentInput;
