import { AmplifyProvider } from '@aws-amplify/ui-react';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './App.css';
import AppRouter from './router/AppRouter';
import { store } from './state/store';
import { amplifyTheme, theme } from '@apps/common-ui';
import { useAuth } from '@apps/common-utilities';

function App() {
    useAuth();

    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <AmplifyProvider theme={amplifyTheme}>
                    <BrowserRouter>
                        <div className="app-container">
                            <AppRouter />
                        </div>
                    </BrowserRouter>
                </AmplifyProvider>
            </Provider>
            <Toaster />
        </ThemeProvider>
    );
}

export default App;
