import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useMatch, useParams } from 'react-router';
import { IUser, UserRoles } from '../../types/models';
import * as S from './index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faBars, faChalkboardTeacher, faChartColumn, faCalendarWeek, faFileContract, faChartBar, faClock } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Routes } from '../../api/Routes';
import toast from 'react-hot-toast';
import { Spinner, useModal } from '@apps/common-ui';
import CareTeamTimer from './components/CareTeamTimer';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { TenantApi } from '../../api/CoachingAPI';
import { TasksModal } from './components/TasksModal';
import Current98976Summary from './components/Current98976Summary';

const Patient = () => {
    const { patientId } = useParams<{ patientId: string }>();
    const [patient, setPatient] = useState<IUser>();
    const [showMenu, setShowMenu] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const { user } = useSelector((state: RootState) => state.session);
    const [tasks, setTasks] = useState<any[]>([]);
    const { isOpen: isTaskModalOpen, openModal: openTaskModal, closeModal: closeTaskModal } = useModal();

    // Just limit to billable roles for now to conver RT/PT/NP
    // But this can be expanded to other roles in the future
    const taskRole = user?.roles.find((role) => role.billable)?.role;

    const fetchPatient = (pid: string) => {
        setLoading(true);
        TenantApi.get(`/${Routes.coaches}/patients/${pid}`).then((fetchedPatient: IUser) => {
            setPatient(fetchedPatient);
        })
            .catch((err) => {
                toast.error('Failed to fetch patient');
            })
            .then(() => {
                TenantApi.get(`/${Routes.coaches}/healthie/patients/${pid}/tasks?role=${taskRole}`).then((fetchedTasks: any[]) => {
                    setTasks(fetchedTasks);
                });
            })
            .catch((err) => {
                toast.error('Failed to fetch tasks');
                setTasks([]);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (patientId) {
            fetchPatient(patientId);
        }
    }, [patientId]);

    const updatePatient = (updates: Partial<IUser>) => {
        if (patient) {
            setPatient({
                ...patient,
                ...updates
            });
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <S.PatientContainer>

            {isTaskModalOpen && (
            <TasksModal
              showModal={isTaskModalOpen}
              dismissModal={closeTaskModal}
              tasks={tasks}
              onSubmit={async (ids: string[]) => {
                const deletedIds: string[] = [];
                // Healthie starts returning 500s if you try to do these all at once, so we need to do them one at a time
                for (let i = 0; i < ids.length; i += 1) {
                    const id = ids[i];
                    await TenantApi.delete(`/${Routes.coaches}/healthie/tasks/${id}`).then(r => {
                        if (r != null) {
                            deletedIds.push(id);
                        }
                    });
                }
                if (deletedIds.length === ids.length) {
                    toast.success('Tasks deleted');
                } else {
                    toast.error('Failed to delete all tasks');
                }
                setTasks(tasks.filter((task) => !deletedIds.includes(task.id)));
                closeTaskModal();
                return Promise.resolve();
              }}
            />
        )}

            <S.PatientNav expanded={showMenu}>
                {showMenu
                  && (
                  <>
                      <S.PatientNavTitle>{`${patient?.firstName} ${patient?.lastName}`}</S.PatientNavTitle>
                      <S.PatientNavSubtitle>{patient?.email}</S.PatientNavSubtitle>
                  </>
                )}
                <S.PatientNavMenu onClick={() => setShowMenu(!showMenu)} expanded={showMenu}>
                    <FontAwesomeIcon icon={faBars as IconProp} />
                </S.PatientNavMenu>
                {
                    tasks?.length > 0
                    && (
                    <S.PaitentNavTasks
                      expanded={showMenu}
                      onClick={openTaskModal}
                    >
                        {showMenu && <>Tasks</>}
                        <S.PatientNavTasksCount>{tasks.length}</S.PatientNavTasksCount>
                    </S.PaitentNavTasks>
)
                }

                <S.PatientNavLink
                  expanded={showMenu}
                  to=""
                  end
                >
                    <FontAwesomeIcon icon={faAddressCard as IconProp} />
                    {showMenu && <>Profile</>}
                </S.PatientNavLink>
                <S.PatientNavLink
                  expanded={showMenu}
                  to="action-plans"
                >
                    <FontAwesomeIcon icon={faFileContract as IconProp} />
                    {showMenu && <>Action Plans</>}
                </S.PatientNavLink>
                <S.PatientNavLink
                  expanded={showMenu}
                  to="exercise-plan"
                >
                    <FontAwesomeIcon icon={faCalendarWeek as IconProp} />
                    {showMenu && <>Exercise Plans</>}
                </S.PatientNavLink>
                <S.PatientNavLink
                  expanded={showMenu}
                  to="exercise-reporting"
                >
                    <FontAwesomeIcon icon={faChartColumn as IconProp} />
                    {showMenu && <>Exercise Reporting</>}
                </S.PatientNavLink>
                <S.PatientNavLink
                  expanded={showMenu}
                  to="rmt-reporting"
                >
                    <FontAwesomeIcon icon={faChartBar as IconProp} />
                    {showMenu && <>RMT Reporting</>}
                </S.PatientNavLink>
                <S.PatientNavLink
                  expanded={showMenu}
                  to="learning-reporting"
                >
                    <FontAwesomeIcon icon={faChalkboardTeacher as IconProp} />
                    {showMenu && <>Course Reporting</>}
                </S.PatientNavLink>
                <S.PatientNavLink
                  expanded={showMenu}
                  to="time-tracking"
                >
                    <FontAwesomeIcon icon={faClock as IconProp} />
                    {showMenu && <>Billing</>}
                </S.PatientNavLink>
                { user?.roles.some((role) => role.billable) && <CareTeamTimer expanded={showMenu} />}
                {user?.roles.some((role) => role.billable && role.role === UserRoles.PHYSICAL_THERAPIST) && <Current98976Summary expanded={showMenu} />}
            </S.PatientNav>
            <S.PatientContentContainer menuExpanded={showMenu}>
                {patient && <Outlet context={{ patient, updatePatient }} />}
            </S.PatientContentContainer>
        </S.PatientContainer>
    );
};

export default Patient;
