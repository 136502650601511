import { Buttons, useModal } from '@apps/common-ui';
import React, { PropsWithChildren } from 'react';
import ManageModuleModal from '../ManageModuleModal';
import * as S from './index.styles';
import { LearningTypes, slugify } from '@apps/common-utilities';

type Props = {
    module: LearningTypes.IModule,
    courseId: number,
    onDeleteModule: (moduleId: number) => void,
    onUpdateModule: (moduleId: number, data: any) => void,
    existingModuleTitles: string[],
    isSaving?: boolean
}

const ModuleSummary = ({ module, courseId, onDeleteModule, onUpdateModule, children, existingModuleTitles, isSaving }: PropsWithChildren<Props>) => {
    const { isOpen, openModal, closeModal } = useModal();

    const updateModule = (data: any) => {
        if (!module.id) {
            return;
        }
        onUpdateModule(module.id, data);
        closeModal();
    };

    const onDelete = () => {
        if (!module.id) {
            return;
        }
        onDeleteModule(module.id);
        closeModal();
    };

    if (!module) {
        return null;
    }

    return (
        <S.ModuleSummaryContainer>
            {isOpen && (
                <ManageModuleModal
                  moduleId={module.id}
                  modalTitle="Update Module"
                  defaultValues={{
                    title: module.title,
                    thumbnailUrl: module.thumbnailUrl,
                    unlockAfterTargetDate: module.unlockAfterTargetDate,
                  }}
                  onDismiss={closeModal}
                  onSubmit={updateModule}
                  onDelete={onDelete}
                  existingModuleTitles={existingModuleTitles}
                  isSaving={isSaving}
                />
            )}
            <S.ModuleSummaryHeaderContainer>
                <img src={module.thumbnailUrl || ''} alt="Module Thumbnail" width="80" height="80" />
                <S.ModuleSummaryHeader>
                    <h3>{module.title}</h3>
                    <Buttons.Button buttonType="tertiary" onClick={openModal}>Manage Module</Buttons.Button>
                </S.ModuleSummaryHeader>
            </S.ModuleSummaryHeaderContainer>
            <hr />
            <S.SubmoduleList>
                {module?.subModules?.map((submodule: LearningTypes.ISubModule) => {
                    let link = '';
                    let prefix = '';
                    const slugifiedname = slugify.toSlug('Learning something something');
                    if (submodule.subModuleType === LearningTypes.SubModuleType.LESSON) {
                        link = `/learning/courses/${courseId}/modules/${module.id}/${slugifiedname}/lessons/${submodule.id}`;
                    } else if (submodule.subModuleType === LearningTypes.SubModuleType.QUIZ) {
                        prefix = 'Quiz';
                        link = `/learning/courses/${courseId}/modules/${module.id}/${slugifiedname}/quizzes/${submodule.id}`;
                    }
                    return (
                        <div key={submodule.id}>
                            <S.SubmoduleListItem>
                                <h4>{submodule.subModuleOrder} {prefix && `${prefix}: `}{submodule.title}</h4>
                                <Buttons.LinkButton
                                  to={link}
                                  buttonType="tertiary"
                                >
                                    Edit
                                </Buttons.LinkButton>
                            </S.SubmoduleListItem>
                            <hr />
                        </div>
                    );
                })}
            </S.SubmoduleList>
            <S.ModuleActions>
                {children}
            </S.ModuleActions>
        </S.ModuleSummaryContainer>
    );
};

export default ModuleSummary;
