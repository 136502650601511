import { Buttons, useConfirmDialog, Inputs } from '@apps/common-ui';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { PageHeader } from '../../components/common/commonStyled';
import QuizQuestion from './components/QuizQuestion';
import * as S from './index.styles';
import { LearningTypes, slugify, typeUtils } from '@apps/common-utilities';
import * as quizUtils from './utils';
import { useNavigate, useParams } from 'react-router';
import { Routes } from '../../api/Routes';
import toast from 'react-hot-toast';
import { TenantApi } from '../../api/CoachingAPI';

export interface IManagedAnswer extends LearningTypes.IAnswer {
    tmpId?: string;
}

export type IManagedQuestion = typeUtils.Modify<LearningTypes.IQuestion, {
    id?: number;
    tmpId: string;
    collapsed: boolean;
    answers: IManagedAnswer[];
}>;

type RouteParams = {
    quizId?: string;
    courseId: string;
    moduleId: string;
    moduleName: string;
}

const ManageQuiz = () => {
    const { register, reset, watch, setValue, formState: { errors }, handleSubmit, control } = useForm({
        defaultValues: {
            title: '',
            questions: [] as any
        }
    });
    const { fields: questionFields, append: addQuestion, remove: removeQuestion, update: updateQuestion } = useFieldArray({
        control,
        name: 'questions',
        shouldUnregister: true
    });
    const [questions, setQuestions] = useState<IManagedQuestion[]>([]);
    const [quiz, setQuiz] = useState<LearningTypes.IQuiz>();
    const { ConfirmationDialog, confirm } = useConfirmDialog({
        message: 'Are you sure you want to delete this quiz?',
        cancelType: 'danger'
    });
    const { quizId, courseId, moduleId, moduleName } = useParams<RouteParams>();
    const readableModuleName = moduleName && slugify.fromSlug(moduleName);
    const navigate = useNavigate();

    const fetchQuiz = () => {
        TenantApi.get(`/${Routes.coaches}/${Routes.courses}/quizzes/${quizId}`).then(res => {
            const defaultValues = {
                ...res,
                questions: res.questions.map(q => ({
                    ...q,
                    collapsed: false
                }))
            };
            reset(defaultValues);
            setQuiz(res);
        });
    };

    useEffect(() => {
        if (quizId && !questions.length) {
            fetchQuiz();
        }
    }, [quizId]);

    const allQuestionsHaveAnswers = (quizToCheck: LearningTypes.IQuiz) => {
        // every question should have at least one correct answer
        return quizToCheck.questions.every((question) => {
            return question.answers.some((answer) => answer.correct);
        });
    };

    const allQuestionsHaveText = (quizToCheck: LearningTypes.IQuiz) => {
        return quizToCheck.questions.every((question) => {
            // replace html tags and spaces with empty string and then check the question length and content
            const cleanString = question.question.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, '');
            // The string should have a length greater than 0 and contain letters
            return cleanString.length > 0 && (cleanString.match(/[a-zA-Z]/g) || []).length > 0;
        });
    };

    const onSubmit = (data: any) => {
        if (quizId) {
            const payload = {
                ...quiz,
                ...data
            };
            // if there are questions without a correct answer show the error toast and don't update the quiz
            if (!allQuestionsHaveAnswers(payload)) {
                toast.error('Every question should have at least one correct answer');
                return;
            }
            if (!allQuestionsHaveText(payload)) {
                toast.error('Every question should contain text');
                return;
            }
            TenantApi.put(`/${Routes.coaches}/${Routes.courses}/${courseId}/modules/${moduleId}/quizzes/${quizId}`, payload).then(res => {
                toast.success('Quiz saved successfully');
                navigate(-1);
            });
        } else {
            const payload = quizUtils.formatQuizDataForCreate(data);
            if (!allQuestionsHaveAnswers(payload)) {
                toast.error('Every question should have at least one correct answer');
                return;
            }
            if (!allQuestionsHaveText(payload)) {
                toast.error('Every question should contain text');
                return;
            }
            TenantApi.post(`/${Routes.coaches}/${Routes.courses}/${courseId}/modules/${moduleId}/quizzes`, payload).then(res => {
                toast.success('Quiz saved successfully');
                navigate(-1);
            });
        }
    };

    const onDeleteQuiz = (e: any) => {
        e.preventDefault();
        confirm().then((confirmed) => {
            if (confirmed) {
                TenantApi.delete(`/${Routes.coaches}/${Routes.courses}/${courseId}/modules/${moduleId}/quizzes/${quizId}`).then(res => {
                    toast.success('Quiz deleted successfully');
                    navigate(-1);
                }).catch(err => {
                    toast.error('Error deleting quiz');
                });
            }
        });
    };

    const onCollapseOrExpandAll = (e: any, collapsed: boolean) => {
        e.preventDefault();
        const questionValues = watch('questions');
        const updatedQuestions = questionValues.map((question: any) => ({
            ...question,
            collapsed
        }));
        reset({
            ...watch(),
            questions: updatedQuestions
        });
    };

    const appendQuestion = (e: any) => {
        e.preventDefault();
        addQuestion({
            question: '',
            answers: [],
        });
    };

    const onToggleCollapse = (e: any, index: number) => {
        e.preventDefault();
        const questionValues = watch('questions');
        const updatedQuestions = questionValues.map((question: any, i: number) => {
            if (i === index) {
                return {
                    ...question,
                    collapsed: !question.collapsed
                };
            }
            return question;
        });
        reset({
            ...watch(),
            questions: updatedQuestions
        });
    };

    return (
        <S.QuizForm>
            <ConfirmationDialog />
            <PageHeader>
                <S.Header>
                    <h2>Manage Quiz</h2>
                    <span>{readableModuleName}</span>
                </S.Header>
                {quizId && (
                    <Buttons.Button
                      buttonType="tertiary"
                      buttonStyle="danger"
                      onClick={onDeleteQuiz}
                    >
                        Delete Quiz
                    </Buttons.Button>
                )}
            </PageHeader>
            <div>
                <Inputs.InputContainer error={errors.title}>
                    <Inputs.Label>Quiz Name</Inputs.Label>
                    <Inputs.Input
                      id="quizTitle"
                      placeholder="Quiz Name"
                      {...register('title', { required: true })}
                    />
                </Inputs.InputContainer>
            </div>
            <hr />
            <S.ViewActions>
                <Buttons.Button buttonType="tertiary" onClick={(e) => onCollapseOrExpandAll(e, false)}>+ Expand All</Buttons.Button>
                <Buttons.Button buttonType="tertiary" onClick={(e) => onCollapseOrExpandAll(e, true)}>- Collapse All</Buttons.Button>
            </S.ViewActions>
            <hr />
            {questionFields.map((question, index) => (
                <QuizQuestion
                  key={question.id}
                  index={index}
                  control={control}
                  onToggleCollapse={(e) => onToggleCollapse(e, index)}
                  register={register}
                  removeQuestion={() => removeQuestion(index)}
                  watch={watch}
                />
            ))}
            <Buttons.Button buttonType="secondary" onClick={appendQuestion}>+ Add Question</Buttons.Button>
            <hr />
            <Buttons.Button onClick={handleSubmit(onSubmit)}>Save Quiz</Buttons.Button>
        </S.QuizForm>
    );
};

export default ManageQuiz;
