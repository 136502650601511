import { Modal, Inputs, Buttons, useConfirmDialog } from '@apps/common-ui';
import { LearningTypes } from '@apps/common-utilities';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { uploadFile } from '../../../../utils/fileUpload';
import * as S from './index.styles';

type Props = {
    moduleId?: number;
    modalTitle: string;
    onDismiss: () => void;
    onSubmit: (data: LearningTypes.ICreateModule) => void;
    defaultValues?: LearningTypes.ICreateModule;
    onDelete?: () => void;
    existingModuleTitles: string[];
    isSaving?: boolean;
}

const ImagePreview = ({ files = [] }: { files: File[] | null }) => {
    if (!files || files.length === 0) {
        return null;
    }
    const imageFile = files[0];
    const src = URL.createObjectURL(imageFile);

    return (
        <img src={src} alt="Module" width="100" height="100" />
    );
};

const ManageModuleModal = ({ moduleId, modalTitle, onDismiss, defaultValues, onSubmit, onDelete, existingModuleTitles, isSaving }: Props) => {
    const [uploadLoading, setUploadLoading] = useState(false);
    const { register, getValues, watch, formState: { errors }, handleSubmit, setError } = useForm({
        defaultValues: {
            ...defaultValues,
            imageFile: null,
        } || {},
    });
    const { ConfirmationDialog, confirm } = useConfirmDialog({
        message: 'Are you sure you want to delete this module? This cannot be undone.'
    });

    const formatModuleForUpdate = async (data: any) => {
        if (data.imageFile && data.imageFile.length > 0) {
            setUploadLoading(true);
            const fileUrl = await uploadFile(data.imageFile[0]);
            data.thumbnailUrl = fileUrl;
        }
        return data;
    };

    const formatModuleForCreate = async (data: any) => {
        setUploadLoading(true);
        const fileUrl = await uploadFile(data.imageFile[0]);
        if (!fileUrl) {
            setUploadLoading(false);
        }
        const moduleData: LearningTypes.ICreateModule = {
            title: data.title,
            thumbnailUrl: fileUrl,
            unlockAfterTargetDate: false,
        };
        if (data.unlockAfterTargetDate === 'true') {
            moduleData.unlockAfterTargetDate = true;
        }
        return moduleData;
    };

    const checkDuplicateTitle = (title: string) => {
        if (defaultValues?.title?.toLowerCase() === title.toLowerCase()) {
            return false; // Allow if the title is unchanged
        }
        return existingModuleTitles.some(existingTitle => existingTitle.toLowerCase() === title.toLowerCase());
    };

    const onSave = async (data: any) => {
        if (!data) {
            return;
        }
        if (checkDuplicateTitle(data.title)) {
            setError('title', {
                type: 'duplicate',
                message: 'Module title already exists',
            });
            return;
        }
        if (!defaultValues?.thumbnailUrl && (!data.imageFile || data.imageFile.length === 0)) {
            setError('imageFile', {
                type: 'required',
                message: 'Image is required',
            });
            return;
        }
        let submissionData;
        if (moduleId) {
            submissionData = await formatModuleForUpdate(data);
        } else {
            submissionData = await formatModuleForCreate(data);
        }

        if (submissionData) {
            await onSubmit(submissionData);
        }
    };

    const onDeleteModule = (e: any) => {
        e.preventDefault();
        confirm().then((confirmed) => {
            if (confirmed && onDelete) {
                onDelete();
            }
        });
    };

    return (
        <Modal showModal dismissModal={onDismiss} title={modalTitle}>
            <ConfirmationDialog />
            <S.ModuleForm onSubmit={handleSubmit(onSave)}>
                <S.FormContent>
                    <S.Column1>
                        <Inputs.InputContainer error={errors.title}>
                            <Inputs.Label>Module Name</Inputs.Label>
                            <Inputs.Input
                              id="title"
                              placeholder="Module Name"
                              {...register('title', { required: true })}
                            />
                        </Inputs.InputContainer>
                        <Inputs.InputContainer>
                            <Inputs.Label>Unlock Module On</Inputs.Label>
                            <Inputs.Select
                              inputSize="large"
                              {...register('unlockAfterTargetDate')}
                            >
                                <option value="false">Upon previous module completion only</option>
                                <option value="true">Upon previous module completion or scheduled time</option>
                            </Inputs.Select>
                        </Inputs.InputContainer>
                    </S.Column1>
                    <div>
                        <Inputs.InputContainer error={errors.imageFile}>
                            <Inputs.Label>Module Image</Inputs.Label>
                            <S.ModuleImageContainer>
                                {watch('imageFile')
                                    ? <ImagePreview files={getValues('imageFile')} />
                                    : defaultValues?.thumbnailUrl && <img src={defaultValues?.thumbnailUrl} alt="Module" />}
                                <Inputs.Input
                                  type="file"
                                  id="imageFile"
                                  accept="image/*"
                                  {...register('imageFile')}
                                />
                            </S.ModuleImageContainer>
                        </Inputs.InputContainer>
                    </div>
                </S.FormContent>
                <S.Actions>
                    <S.InnerActions>
                        <Buttons.Button type="submit" disabled={isSaving || uploadLoading}>
                            {uploadLoading ? <FontAwesomeIcon icon={faSpinner as IconProp} spin /> : 'Save'}
                        </Buttons.Button>
                        <Buttons.Button buttonType="tertiary" onClick={onDismiss}>Cancel</Buttons.Button>
                    </S.InnerActions>
                    {onDelete && <Buttons.Button buttonStyle="danger" buttonType="tertiary" onClick={onDeleteModule}>Delete Module</Buttons.Button>}
                </S.Actions>
            </S.ModuleForm>
        </Modal>
    );
};

export default ManageModuleModal;
