import { Buttons, Inputs } from '@apps/common-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router';
import { IUser, MedicalCondition, UserRoles } from '../../../../types/models';
import * as yup from 'yup';
import * as S from './index.styles';
import { Header } from '../PatientSummary/index.styles';
import { useActionLoader } from '../../../../hooks/useActionLoader';
import { patchUserAssignment } from '../../../../state/reducers/coaching';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import UserAccessSelection from './components/UserAccessSelection';
import { MultiSelect } from '@apps/common-ui/src/Input';

const EditPatient = () => {
    const { patient, updatePatient } = useOutletContext<{ patient: IUser, updatePatient:(updates: any) => void }>();
    const [editedPatient, setEditedPatient] = useState<IUser>();
    const { patients } = useSelector((store: RootState) => store.coaching);

    const { callAction: updatePatientAssignment, done } = useActionLoader(patchUserAssignment, {
        successMessage: 'Patient assignment updated successfully',
        errorMessage: 'Error updating patient assignment'
    });
    const { coaches } = useSelector((state: RootState) => state.coaching);
    const navigate = useNavigate();

    type FormData = {
        primaryCondition: MedicalCondition | null
        groupCode: string | null
        primaryRespiratoryTherapistId: string | null
        primaryPhysicalTherapistId: string | null
        primaryNursePractitionerId: string | null
        assignedCoaches: {label: string, value: string}[] | null
    }

    const schema = yup.object({
        gropCode: yup.string().nullable(),
        primaryCondition: yup.string().nullable(),
        primaryRespiratoryTherapistId: yup.string().nullable(),
        primaryPhysicalTherapistId: yup.string().nullable(),
        primaryNursePractitionerId: yup.string().nullable(),
        assignedCoaches: yup.array().of(yup.object({ label: yup.string(), value: yup.string() })).nullable()
    });

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        setEditedPatient(patient);
    }, [patient]);

    useEffect(() => {
        if (done) {
            updatePatient(patients.find(p => p.id === patient.id));
            navigate(-1);
        }
    }, [done, patients]);

    const multiSelectOptions = coaches.map(c => ({ value: c.userId, label: c.name }));

    const onSubmit = (data: FormData) => {
        const userAssignment = {
            coachAssignment: {
                primaryRespiratoryTherapistId: data.primaryRespiratoryTherapistId,
                primaryPhysicalTherapistId: data.primaryPhysicalTherapistId,
                primaryNursePractitionerId: data.primaryNursePractitionerId,
                assignedCoachIds: data.assignedCoaches?.map(c => c.value) || []
            },
            groupCode: data.groupCode,
            primaryCondition: data.primaryCondition
        };
        updatePatientAssignment({
            patientId: patient.id,
            userAssignment
        });
    };

    return (
        editedPatient ? (
            <S.EditPatientForm onSubmit={handleSubmit(v => onSubmit(v as FormData))} autoComplete="off">
                <Header>CONTACT</Header>
                <S.PatientFieldRow>
                    <Inputs.InputContainer>
                        <Inputs.Label>First name</Inputs.Label>
                        <Inputs.Input defaultValue={editedPatient.firstName} disabled />
                        <Inputs.ErrorMessage>{errors.firstName?.message}</Inputs.ErrorMessage>
                    </Inputs.InputContainer>
                    <Inputs.InputContainer>
                        <Inputs.Label>Last name</Inputs.Label>
                        <Inputs.Input defaultValue={editedPatient.lastName} disabled />
                        <Inputs.ErrorMessage>{errors.lastName?.message}</Inputs.ErrorMessage>
                    </Inputs.InputContainer>
                </S.PatientFieldRow>
                <S.PatientFieldRow>
                    <Inputs.InputContainer>
                        <Inputs.Label>Email</Inputs.Label>
                        <Inputs.Input defaultValue={editedPatient.email} disabled />
                        {errors.email?.message && <Inputs.ErrorMessage>{errors.email}</Inputs.ErrorMessage>}
                    </Inputs.InputContainer>
                    <Inputs.InputContainer>
                        <Inputs.Label>Phone number</Inputs.Label>
                        <Inputs.Input defaultValue={editedPatient.phoneNumber} disabled />
                        <Inputs.ErrorMessage>{errors.phoneNumber?.message}</Inputs.ErrorMessage>
                    </Inputs.InputContainer>
                </S.PatientFieldRow>
                <Header>MEDICAL INFORMATION</Header>
                <S.PatientFieldRow>
                    <Inputs.InputContainer>
                        <Inputs.Label htmlFor="primaryCondition">Primary Condition</Inputs.Label>
                        <Inputs.Select
                          defaultValue={editedPatient.medicalCondition || ''}
                          {...register('primaryCondition', {
                            setValueAs: v => v === '' ? null : v,
                            })}
                        >
                            <option value="">Select</option>
                            {Object.keys(MedicalCondition).map((k) => (
                                <option key={MedicalCondition[k]} value={MedicalCondition[k]}>{MedicalCondition[k]}</option>
                            ))}
                        </Inputs.Select>
                    </Inputs.InputContainer>
                </S.PatientFieldRow>
                <Header>ACCESS</Header>
                <p>This user should have access to the following (select any or all to give access to this user)</p>
                <Controller
                  name="groupCode"
                  control={control}
                  defaultValue={editedPatient.groupCode}
                  render={({ field: { onChange, value } }) => (
                      <UserAccessSelection groupCode={value} onChange={onChange} />
                        )}
                />
                <Header>MANAGE PROVIDERS</Header>
                <S.PatientFieldRow>
                    <Inputs.InputContainer>
                        <Inputs.Label> Primary Physical Therapist</Inputs.Label>
                        <Inputs.Select
                          defaultValue={editedPatient.primaryPhysicalTherapist?.userId || ''}
                          {...register('primaryPhysicalTherapistId', {
                            setValueAs: v => v === '' ? null : v,
                          })}
                        >
                            <option value="">Select</option>
                            {coaches.filter(c => c.roles.filter(r => r.role === UserRoles.PHYSICAL_THERAPIST).length > 0).map(
                                c => <option key={c.userId} value={c.userId}>{c.name}</option>
                            )}
                        </Inputs.Select>
                    </Inputs.InputContainer>

                    <Inputs.InputContainer>
                        <Inputs.Label>Primary Respiratory Therapist</Inputs.Label>
                        <Inputs.Select
                          defaultValue={editedPatient.primaryRespiratoryTherapist?.userId || ''}
                          {...register('primaryRespiratoryTherapistId', {
                            setValueAs: v => v === '' ? null : v,
                          })}
                        >
                            <option value="">Select</option>
                            {coaches.filter(c => c.roles.filter(r => r.role === UserRoles.RESPIRATORY_THERAPIST).length > 0).map(
                                c => <option key={c.userId} value={c.userId}>{c.name}</option>
                            )}
                        </Inputs.Select>
                    </Inputs.InputContainer>
                </S.PatientFieldRow>

                <S.PatientFieldRow>
                    <Inputs.InputContainer>
                        <Inputs.Label> Primary Nurse Practictioner</Inputs.Label>
                        <Inputs.Select
                          defaultValue={editedPatient.primaryNursePractitioner?.userId || ''}
                          {...register('primaryNursePractitionerId', {
                            setValueAs: v => v === '' ? null : v,
                          })}
                        >
                            <option value="">Select</option>
                            {coaches.filter(c => c.roles.filter(r => r.role === UserRoles.NURSE_PRACTITIONER).length > 0).map(c => <option key={c.userId} value={c.userId}>{c.name}</option>)}
                        </Inputs.Select>
                    </Inputs.InputContainer>

                    <Inputs.InputContainer>
                        <Inputs.Label>Additional Assignments</Inputs.Label>
                        <Controller
                          name="assignedCoaches"
                          control={control}
                          defaultValue={multiSelectOptions.filter(option => editedPatient.coaches.filter(c => ![
                                            editedPatient.primaryNursePractitioner?.userId,
                                            editedPatient.primaryPhysicalTherapist?.userId,
                                            editedPatient.primaryRespiratoryTherapist?.userId].includes(c.userId))
                                            .map(c => c.userId).includes(option.value))}
                          render={({ field }) => (
                              <MultiSelect
                                options={multiSelectOptions}
                                {...field}
                              />
                            )}
                        />
                    </Inputs.InputContainer>

                </S.PatientFieldRow>

                <div><Inputs.ErrorMessage>{(errors.coachAssignment as any)?.primaryCoachId.message}</Inputs.ErrorMessage></div>
                <Buttons.Button type="submit">Save</Buttons.Button>
                <Buttons.LinkButton type="submit" buttonType="secondary" to="../">Cancel</Buttons.LinkButton>
            </S.EditPatientForm>
        ) : null
    );
};

export default EditPatient;
