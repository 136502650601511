import styled from 'styled-components';

export const CourseName = styled.div`
    padding: 10px 0;
    h3 {
        margin-bottom: 8px;
    }
    span {
        color: ${props => props.theme.shades.grey};
        font-weight: bold;
    }
`;

export const CourseListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
    overflow: auto;
    padding: 0 50px;
`;
