import React, { useEffect, useState } from 'react';
import ManageUserLearningModal from './components/ManageUserLearningModal';
import { Buttons, useModal, Spinner } from '@apps/common-ui';
import ModuleSummarySubmission from '../Course/components/ModuleSummary/ModuleSummarySubmission';
import * as S from './index.styles';
import { LearningTypes } from '@apps/common-utilities';
import { useParams } from 'react-router';
import { TenantApi } from '../../api/CoachingAPI';

const LearningReporting = () => {
    const { closeModal, isOpen, openModal } = useModal();
    const { patientId } = useParams();
    const [courseSubmission, setCourseSubmission] = useState<LearningTypes.ICourseReport | null>(null);
    const [courseSummary, setCourseSummary] = useState<LearningTypes.ICourseSummary | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const getCourseProgress = () => {
        TenantApi.get(`/coaches/users/${patientId}/courses/assigned/report/full`).then((res) => {
            setCourseSubmission(res);
            setLoading(false);
        }).catch(() => {
            // no course found
            setCourseSubmission(null);
            setLoading(false);
        });
        TenantApi.get(`/coaches/users/${patientId}/courses/assigned/report/summary`).then((res) => {
            setCourseSummary(res);
        }).catch(() => {
            // no course found
            setCourseSummary(null);
        });
    };

    useEffect(() => {
        getCourseProgress();
    }, [patientId, isOpen]);

    return (
        <div>
            {!loading && courseSubmission && (
                <>
                    <S.Header>
                        <h1>Course Progress</h1>
                        <S.ButtonContainer>
                            <Buttons.LinkButton to={`/learning/courses/${courseSummary?.courseId}`}>Edit Course</Buttons.LinkButton>
                            <Buttons.Button onClick={openModal}>Manage User Program</Buttons.Button>
                        </S.ButtonContainer>
                    </S.Header>
                    <h2>{courseSubmission.title}</h2>
                    <ManageUserLearningModal showModal={isOpen} dismissModal={closeModal} currentCourse={courseSummary} />
                    {courseSubmission.modules.map((module) => (
                        <ModuleSummarySubmission
                          moduleSubmission={module}
                        />
                ))}
                </>
            )}
            {!loading && !courseSubmission && (
                <div>
                    <h1>No Course Found</h1>
                </div>
            )}
            {loading && (
                <S.Container>
                    <Spinner />
                </S.Container>
            )}
        </div>
    );
};

export default LearningReporting;
