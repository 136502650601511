import styled from 'styled-components';

export const ModuleSummaryContainer = styled.div`
    margin: 50px 0;
    max-width: 1000px
`;

export const ModuleSummaryHeaderContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ModuleSummaryHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    width: 100%;

    h3 {
        margin: 10px;
    }
`;

export const SubmoduleList = styled.div`

`;

export const SubmoduleListItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ModuleActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
`;

export const SubmissionStatus = styled.p<{status: string}>`
color: ${props => {
        switch (props.status) {
            case 'success':
                return props.theme.success.medium;
            case 'danger':
                return props.theme.danger.medium;
            case 'info':
                return props.theme.primary.medium;
            default:
                return props.theme.primary.medium;
        }
    }};
`;

export const SubmissionResults = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
`;

export const QuizScore = styled.p`
    font-weight: bold;
`;

export const CompletionDate = styled.p`
    color: ${props => props.theme.shades.darkGrey};
`;

export const ModuleItemButtons = styled.div`
    display: flex;

`;

export const SubmissionResultText = styled.p`
  text-transform: capitalize;
`;
