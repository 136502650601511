import { Buttons, Inputs, Modal } from '@apps/common-ui';
import React, { useEffect, useState } from 'react';
import * as S from './index.styles';
import { LearningTypes } from '@apps/common-utilities';
import { fetchCourses } from '../../../../state/reducers/learning';
import { useActionLoader } from '../../../../hooks/useActionLoader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { useParams } from 'react-router';
import { formatDateForInput } from '../../../../utils/dateUtils';
import toast from 'react-hot-toast';
import { TenantApi } from '../../../../api/CoachingAPI';

interface Props {
    showModal: boolean;
    dismissModal: () => void;
    currentCourse: LearningTypes.ICourseSummary | null;
}

enum CourseStatus {
    ACTIVE = 'ACTIVE',
    PAUSED = 'PAUSED',
    COMPLETE = 'COMPLETE',
    NO_COURSE = 'NO_COURSE',
}

interface CourseStatusObject {
    status: CourseStatus;
    text: string;
    color: 'success' | 'info' | 'danger';
}

enum PauseReason {
    ILLNESS = 'ILLNESS',
    NON_PAYMENT = 'NON_PAYMENT',
}

const ManageUserLearningModal = ({ showModal, dismissModal, currentCourse }: Props) => {
    const { patientId } = useParams();
    const [viewingCourse, setViewingCourse] = useState<number | null>(currentCourse?.courseId || null);
    const [courseStatus, setCourseStatus] = useState<CourseStatusObject>({ color: 'info', status: CourseStatus.NO_COURSE, text: '' });
    const { callAction: getCourses } = useActionLoader(fetchCourses);
    const { courses } = useSelector((state: RootState) => state.learning);
    const [pauseReason, setPauseReason] = useState<PauseReason | null>(PauseReason.ILLNESS);
    const [startDate, setStartDate] = useState<string>(formatDateForInput(new Date(currentCourse?.startDate || Date.now())));

    const assignCourse = () => {
        // assign course to user
        const body = {
            courseId: viewingCourse,
            startDate,
            userId: patientId,
        };
        TenantApi.post('/coaches/course-assignments', body).then(() => {
            toast.success('Course Assigned');
            dismissModal();
        }).catch(() => {
            toast.error('Error Assigning Course');
        });
    };

    const updateCourse = () => {
        const body = {
            startDate: currentCourse?.startDate,
            isPaused: courseStatus.status === CourseStatus.PAUSED,
            isCompleted: courseStatus.status === CourseStatus.COMPLETE,
            pauseReason,
        };
        TenantApi.patch(`/coaches/course-assignments/${currentCourse?.assignmentId}`, body).then(() => {
            if (courseStatus.status === CourseStatus.PAUSED) {
                toast.success('Course Paused');
            } else if (courseStatus.status === CourseStatus.ACTIVE) {
                toast.success('Course Resumed');
            } else {
                toast.success('Course Completed');
            }
            dismissModal();
        }).catch(() => {
            toast.error('Error Updating Course');
        });
    };

    const submit = () => {
        // assign new course if current course is complete or no course
        if (currentCourse?.isCompleted || !currentCourse?.assignmentId) {
            assignCourse();
        } else {
            // update course if current course is active or paused
            updateCourse();
        }
    };

    const getCourseStatus = () => {
        // get course status
        if (!currentCourse) {
            setCourseStatus({ status: CourseStatus.NO_COURSE, text: 'No Course Assigned', color: 'info' });
            return;
        }
        setViewingCourse(currentCourse.courseId);
        if (currentCourse.isPaused) {
            setCourseStatus({ status: CourseStatus.PAUSED, text: 'Assigned Course is Paused', color: 'danger' });
            setPauseReason(currentCourse.pauseReason as PauseReason);
            return;
        }
        if (currentCourse.isCompleted) {
            setCourseStatus({ status: CourseStatus.COMPLETE, text: 'Assigned Course is Complete', color: 'success' });
            return;
        }
        setCourseStatus({ status: CourseStatus.ACTIVE, text: 'Assigned Course is Active', color: 'success' });
    };

    useEffect(() => {
        getCourses();
        getCourseStatus();
        if (currentCourse) {
            setStartDate(formatDateForInput(new Date(currentCourse.startDate)));
        }
    }, [currentCourse]);

    useEffect(() => {
        if (!currentCourse) {
            setViewingCourse(courses[0]?.courseId || null);
        }
    }, [courses]);

    return (
        <Modal dismissModal={dismissModal} showModal={showModal} title="Manage Module" style={{ width: '30%' }}>
            <S.Container>
                <S.Section>
                    <Inputs.InputContainer>
                        <Inputs.Label>Select Module to Manage:</Inputs.Label>
                        <Inputs.Select
                          size="large"
                          disabled={(currentCourse?.courseId && !currentCourse.isCompleted) || currentCourse?.isPaused}
                          onChange={(e) => setViewingCourse(e.target.value)}
                        >
                            {courses.map((course) => (
                                <option
                                  key={course.courseId}
                                  value={course.courseId}
                                  selected={course.courseId === currentCourse?.courseId}
                                >
                                    {course.internalTitle}
                                </option>
                            ))}
                        </Inputs.Select>
                    </Inputs.InputContainer>
                    <S.StatusContainer status={courseStatus.color}>
                        <S.StatusText>{courseStatus.text}</S.StatusText>
                    </S.StatusContainer>
                </S.Section>
                <S.Section>
                    <Inputs.InputContainer>
                        <Inputs.Label>Start Date</Inputs.Label>
                        <Inputs.Input
                          style={{ margin: '10px' }}
                          type="date"
                          inputSize={Inputs.InputSize.medium}
                          disabled={(currentCourse?.courseId && !currentCourse.isCompleted) || currentCourse?.isPaused}
                          value={startDate}
                          onChange={(e) => setStartDate(formatDateForInput(new Date(e.target.value)))}
                        />
                    </Inputs.InputContainer>
                    {courseStatus.status === CourseStatus.PAUSED && (
                        <Inputs.InputContainer>
                            <Inputs.Label>Reason</Inputs.Label>
                            <Inputs.Select value={pauseReason} onChange={(e) => setPauseReason(e.target.value)} size="medium">
                                <option value={PauseReason.ILLNESS}>Illness</option>
                                <option value={PauseReason.NON_PAYMENT}>Non-Payment</option>
                            </Inputs.Select>
                        </Inputs.InputContainer>
                    )}
                </S.Section>
                <S.Section>
                    <S.RadioContainer>
                        <Inputs.Radio
                          onClick={() => setCourseStatus({ ...courseStatus, status: CourseStatus.ACTIVE })}
                          disabled={!currentCourse?.isPaused && !currentCourse?.isCompleted}
                          id="active"
                          name="course-active"
                          defaultChecked={courseStatus.status === CourseStatus.ACTIVE || courseStatus.status === CourseStatus.NO_COURSE || courseStatus.status === CourseStatus.COMPLETE}
                          value={CourseStatus.ACTIVE}
                        />
                        <Inputs.Label htmlFor="active">Activate</Inputs.Label>
                    </S.RadioContainer>
                    <S.RadioContainer>
                        <Inputs.Radio
                          onClick={() => setCourseStatus({ ...courseStatus, status: CourseStatus.PAUSED })}
                          disabled={!currentCourse?.courseId || currentCourse.isCompleted}
                          id="paused"
                          name="course-active"
                          defaultChecked={courseStatus.status === CourseStatus.PAUSED}
                          value={CourseStatus.PAUSED}
                        />
                        <Inputs.Label htmlFor="paused">Pause</Inputs.Label>
                    </S.RadioContainer>
                    <S.RadioContainer>
                        <Inputs.Radio
                          onClick={() => setCourseStatus({ ...courseStatus, status: CourseStatus.COMPLETE })}
                          disabled={!currentCourse?.courseId || currentCourse.isCompleted}
                          id="complete"
                          name="course-active"
                          value={CourseStatus.COMPLETE}
                        />
                        <Inputs.Label htmlFor="complete">Complete</Inputs.Label>
                    </S.RadioContainer>
                </S.Section>
                <S.ButtonContainer>
                    <Buttons.Button onClick={submit}>Submit</Buttons.Button>
                </S.ButtonContainer>
            </S.Container>
        </Modal>
    );
};

export default ManageUserLearningModal;
