import styled from 'styled-components';

export const ModuleForm = styled.form`
    width: 100%;
    height: 100%;
    display: flex
    flex-direction: column;
`;

export const FormContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
`;

export const Column1 = styled.div`
    width: 60%;
`;

export const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
`;

export const InnerActions = styled.div`
    display: flex;
`;

export const ModuleImageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;

    img {
        margin-top: 10px;
        border-radius: 3px;
        max-width: 80px;
    }
`;
