import { Buttons, useModal, SegmentedProgressBar } from '@apps/common-ui';
import React, { useEffect, useState } from 'react';
import ManageUserLearningModal from '../../../../../LearningReporting/components/ManageUserLearningModal';
import { LearningTypes } from '@apps/common-utilities';
import * as S from './index.styles';
import { useParams } from 'react-router';
import { format, isFuture } from 'date-fns';
import { ProgramStatusIndicator } from '../../../../../../components/common/commonStyled';
import { NaiveDate } from '../../../../../../utils/dateUtils';
import { TenantApi } from '../../../../../../api/CoachingAPI';

export enum ProgramStatus {
    ACTIVE = 'active',
    COMPLETED = 'completed',
    UNASSIGNED = 'unassigned',
    PAUSED = 'paused'
}

export enum ProgramProgress {
    AHEAD = 'Ahead',
    ON_SCHEDULE = 'On Schedule',
    BEHIND = 'Behind',
    VERY_BEHIND = 'Very Behind',
    NOT_STARTED = 'Not Started'
}

export const ProgramStatusColors = {
    [ProgramProgress.AHEAD]: '#317231',
    [ProgramProgress.ON_SCHEDULE]: '#1f2c61',
    [ProgramProgress.BEHIND]: '#bd9400',
};

const CourseProgress = () => {
    const { patientId } = useParams();
    const [courseSummary, setCourseSummary] = useState<LearningTypes.ICourseSummary | null>(null);
    const [progress, setProgress] = useState<ProgramProgress>(ProgramProgress.AHEAD);
    const [courseProgressSegments, setCourseProgressSegments] = useState<{color: string, filled: boolean}[]>([]);

    const { closeModal, isOpen, openModal } = useModal();

    const getCourseProgressSegments = () => {
        if (!courseSummary) {
            return;
        }

        const segments: {color: string, filled: boolean}[] = [];
        for (let i = 1; i <= courseSummary.numberOfModules; i += 1) {
            let color = ProgramStatusColors[ProgramProgress.ON_SCHEDULE];
            if (i > courseSummary.currentModuleNumber && i <= courseSummary.targetModuleNumber) {
                color = ProgramStatusColors[ProgramProgress.BEHIND];
            }
            if (i > courseSummary.targetModuleNumber && i <= courseSummary.currentModuleNumber) {
                color = ProgramStatusColors[ProgramProgress.AHEAD];
            }
            segments.push({
                color,
                filled: i <= courseSummary.targetModuleNumber || i <= courseSummary.currentModuleNumber,
            });
        }
        setCourseProgressSegments(segments);
    };

    const getCourseProgress = () => {
        if (!courseSummary) {
            return;
        }
        if (isFuture(new Date(courseSummary.startDate))) {
            setProgress(ProgramProgress.NOT_STARTED);
        } else if (courseSummary.currentModuleNumber > courseSummary.targetModuleNumber) {
            setProgress(ProgramProgress.AHEAD);
        } else if (courseSummary.currentModuleNumber === courseSummary.targetModuleNumber) {
            setProgress(ProgramProgress.ON_SCHEDULE);
        } else if (courseSummary.currentModuleNumber < courseSummary.targetModuleNumber && courseSummary.targetModuleNumber - courseSummary.currentModuleNumber <= 2) {
            setProgress(ProgramProgress.BEHIND);
        } else {
            setProgress(ProgramProgress.VERY_BEHIND);
        }
    };

    const getCourseSummary = () => {
        TenantApi.get(`/coaches/users/${patientId}/courses/assigned/report/summary`).then((res) => {
            setCourseSummary(res);
        }).catch((err) => {
            // no course assigned
        });
    };

    useEffect(() => {
        getCourseSummary();
    }, [patientId, isOpen]);

    useEffect(() => {
        getCourseProgressSegments();
        getCourseProgress();
    }, [courseSummary]);

    return (
        <S.Container>
            <ManageUserLearningModal dismissModal={closeModal} showModal={isOpen} currentCourse={courseSummary} />
            {courseSummary && (
                <>
                    <S.Header>
                        <h3>{courseSummary.internalTitle}</h3>
                        <S.CourseStatus progress={progress}>{progress}</S.CourseStatus>
                    </S.Header>
                    {courseSummary.isPaused && <ProgramStatusIndicator status="danger">Paused</ProgramStatusIndicator>}
                    {courseSummary.isCompleted && <ProgramStatusIndicator status="success">Complete</ProgramStatusIndicator>}
                    {isFuture(new Date(courseSummary.startDate)) && <ProgramStatusIndicator status="info">Starts on {new NaiveDate(courseSummary.startDate).toLocaleDateString()}</ProgramStatusIndicator>}
                    {courseSummary.currentModuleNumber && courseSummary.targetModuleNumber && <S.CourseSummary progress={progress}>On module {courseSummary.currentModuleNumber}, should be on module {courseSummary.targetModuleNumber}</S.CourseSummary>}
                    {!courseSummary.currentModuleNumber && !courseSummary.targetModuleNumber && <S.CourseSummary progress={progress}>Course is starting</S.CourseSummary>}
                    {!courseSummary.currentModuleNumber && courseSummary.targetModuleNumber && <S.CourseSummary progress={progress}>Course has not started yet</S.CourseSummary>}
                    <S.Body>
                        <S.CourseDetails>
                            <span>Start Date: {format(new NaiveDate(courseSummary.startDate), 'MM-dd-yyyy')}</span>
                        </S.CourseDetails>
                        <SegmentedProgressBar
                          segments={courseProgressSegments}
                        />
                        <S.CourseDetails>
                            {courseSummary.currentModuleNumber && <span>Current Module: {courseSummary.currentModuleNumber}</span>}
                            { courseSummary.targetModuleNumber && <span>Target Module: {courseSummary.targetModuleNumber}</span>}
                        </S.CourseDetails>
                        <div>
                            <Buttons.Button onClick={openModal}>Manage</Buttons.Button>
                        </div>
                    </S.Body>
                </>
            )}
            {!courseSummary && (
                <>
                    <h3>No Course Assigned</h3>
                    <S.NoCourseButton>
                        <Buttons.Button onClick={openModal}>Assign Course</Buttons.Button>
                    </S.NoCourseButton>
                </>
            )}
        </S.Container>
    );
};

export default CourseProgress;
