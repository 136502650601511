import styled from 'styled-components';
import { ProgramProgress, ProgramStatus } from '.';

export const Container = styled.div`
  width: 90%;

  span {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const CourseStatus = styled.div<{progress: ProgramProgress}>`
  position: absolute;
  right: 0;
  width: max-content;
  padding: 10px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => {
        switch (props.progress) {
            case ProgramProgress.AHEAD:
                return `
                  background-color: ${props.theme.success.dark};
                  color: ${props.theme.shades.white};
                `;
            case ProgramProgress.BEHIND:
                return `
                  background-color: ${props.theme.warning.dark};
                  color: ${props.theme.shades.white};
                `;
            case ProgramProgress.VERY_BEHIND:
                return `
                  background-color: ${props.theme.danger.dark};
                  color: ${props.theme.shades.white};
                `;
            case ProgramProgress.ON_SCHEDULE:
                return `
                  background-color: ${props.theme.primary.dark};
                  color: ${props.theme.shades.white};
                `;
            default:
                return `
                  background-color: ${props.theme.primary.dark};
                  color: ${props.theme.shades.white};
                `;
        }
    }};
`;

export const Body = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
`;

export const CourseDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CourseSummary = styled.p<{progress: ProgramProgress}>`
  display: flex;
  font-weight: bold;
  color: ${(props) => {
        switch (props.progress) {
            case ProgramProgress.AHEAD:
                return props.theme.success.dark;
            case ProgramProgress.BEHIND:

                return props.theme.warning.dark;
            case ProgramProgress.VERY_BEHIND:
                return props.theme.danger.dark;
            case ProgramProgress.ON_SCHEDULE:
                return props.theme.primary.dark;
            default:
                return props.theme.primary.dark;
        }
    }};
`;

export const NoCourseButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;
