import { Buttons, useModal } from '@apps/common-ui';
import { LearningTypes, slugify } from '@apps/common-utilities';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Routes } from '../../api/Routes';
import { PageHeader } from '../../components/common/commonStyled';
import ManageCourseModal from '../../components/ManageCourseModal';
import { useActionLoader } from '../../hooks/useActionLoader';
import { deleteCourse, updateCourse } from '../../state/reducers/learning';
import { monthDateYear, NaiveDate } from '../../utils/dateUtils';
import ManageModuleModal from './components/ManageModuleModal';
import ModuleSummary from './components/ModuleSummary';
import * as S from './index.styles';
import toast from 'react-hot-toast';
import { TenantApi } from '../../api/CoachingAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';

const Course = () => {
    const { isOpen: isCourseModalOpen, openModal: openCourseModal, closeModal: closeCourseModal } = useModal();
    const { isOpen: isModuleModalOpen, openModal: openModuleModal, closeModal: closeModuleModal } = useModal();
    const { courseId } = useParams<{ courseId: string }>();
    const [course, setCourse] = useState<LearningTypes.ICourse | null>(null);
    const [isSavingCourse, setIsSavingCourse] = useState(false);
    const [isSavingModule, setIsSavingModule] = useState(false);
    const { courses } = useSelector((state: RootState) => state.learning);
    let existingModuleTitles: string[] = [];
    let existingInternalTitles: string[] = [];
    const { callAction: saveCourse, done: doneUpdate } = useActionLoader(updateCourse, {
        successMessage: 'Course updated successfully',
        errorMessage: 'Error updating course',
    });
    const { callAction: removeCourse, done: doneDelete } = useActionLoader(deleteCourse, {
        successMessage: 'Course deleted successfully',
        errorMessage: 'Error deleting course',
    });
    const navigate = useNavigate();

    const fetchCourseSummary = async () => {
        if (courseId && !course) {
            const courseSummary = await TenantApi.get(`/${Routes.coaches}/${Routes.courses}/${courseId}/versions/latest`);
            setCourse(courseSummary);
        }
    };

    useEffect(() => {
        fetchCourseSummary();
    }, [courseId]);

    useEffect(() => {
        if (doneDelete) {
            navigate(-1);
        }
    }, [doneDelete]);

    useEffect(() => {
        if (doneUpdate) {
            closeCourseModal();
            setIsSavingCourse(false);
        }
    }, [doneUpdate]);

    const onUpdateCourse = (data: LearningTypes.ICreateCourse) => {
        setIsSavingCourse(true);
        const updatedCourse = {
            ...course,
            ...data
        } as LearningTypes.ICourse;
        saveCourse({ course: updatedCourse });
        setCourse(updatedCourse);
    };

    const onCreateModule = (data: LearningTypes.ICreateModule) => {
        if (!course || isSavingModule) {
            return;
        }
        setIsSavingModule(true);
        TenantApi.post(`/${Routes.coaches}/${Routes.courses}/${courseId}/${Routes.modules}`, data).then((res) => {
            setCourse({
                ...course,
                numberOfModules: course.numberOfModules + 1,
                modules: [...course.modules, res]
            });
            toast.success('Module created');
            closeModuleModal();
        }).catch(() => {
            toast.error('Error creating module');
        }).finally(() => {
            setIsSavingModule(false);
        });
    };

    const onUpdateModule = (moduleId: number, data: LearningTypes.ICreateModule) => {
        if (!course || isSavingModule) {
            return;
        }
        setIsSavingModule(true);
        TenantApi.put(`/${Routes.coaches}/${Routes.courses}/${courseId}/${Routes.modules}/${moduleId}`, data).then(res => {
            toast.success('Module updated');
            setCourse({
                ...course,
                modules: course.modules.map((module) => {
                    if (module.id === moduleId) {
                        return {
                            ...module,
                            ...res
                        };
                    }
                    return module;
                })
            });
            closeModuleModal();
        }).catch(() => {
            toast.error('Error updating module');
        }).finally(() => {
            setIsSavingModule(false);
        });
    };

    const onDeleteModule = (moduleId: number) => {
        if (!moduleId || !course) {
            return;
        }
        TenantApi.delete(`/${Routes.coaches}/${Routes.courses}/${courseId}/modules/${moduleId}`).then(() => {
            toast.success('Module deleted');
            setCourse({
                ...course,
                numberOfModules: course.numberOfModules + 1,
                modules: course?.modules.filter((module) => module.id !== moduleId)
            });
        }).catch(() => {
            toast.error('Error deleting module');
        });
    };

    const onDeleteCourse = () => {
        if (!courseId) {
            return;
        }
        removeCourse({ courseId });
    };

    if (!course) {
        return null;
    }

    if (courses && course) {
        existingModuleTitles = course.modules.map(module => module.title);
        existingInternalTitles = courses.map(availableCourse => availableCourse.internalTitle.toLowerCase());
    }

    return (
        <S.CourseContainer>
            {isCourseModalOpen && (
                <ManageCourseModal
                  onDismiss={() => {
                    closeCourseModal();
                    setIsSavingCourse(false);
                  }}
                  onSave={onUpdateCourse}
                  onDelete={onDeleteCourse}
                  existingInternalTitles={existingInternalTitles}
                  isSaving={isSavingCourse}
                  defaultValues={{ userTitle: course.userTitle, internalTitle: course.internalTitle, courseWeekLength: course.courseWeekLength }}
                />
            )}
            {isModuleModalOpen && (
                <ManageModuleModal
                  modalTitle="Add Module"
                  onDismiss={closeModuleModal}
                  onSubmit={onCreateModule}
                  existingModuleTitles={existingModuleTitles}
                  isSaving={isSavingModule}
                />
            )}
            <PageHeader>
                <S.CourseNameContainer>
                    <h2>{course.userTitle}</h2>
                    <span>Internal name: {course.internalTitle}</span>
                </S.CourseNameContainer>
            </PageHeader>
            <S.ManageButtons>
                <Buttons.Button buttonType="tertiary" onClick={openCourseModal}>Manage Course</Buttons.Button>
            </S.ManageButtons>
            <S.LastUpdated>Last updated: {monthDateYear(new NaiveDate(course.updatedAt))}</S.LastUpdated>
            <S.CourseStats>
                <S.CourseStat>
                    <h4>Assigned to</h4>
                    <span>{course.numberOfAssignments}</span>
                </S.CourseStat>
                <S.CourseStat>
                    <h4>Modules</h4>
                    <span>{course.numberOfModules}</span>
                </S.CourseStat>
                <S.CourseStat>
                    <h4>Lessons</h4>
                    <span>{course.numberOfLessons}</span>
                </S.CourseStat>
                <S.CourseStat>
                    <h4>Quizzes</h4>
                    <span>{course.numberOfQuizzes}</span>
                </S.CourseStat>
            </S.CourseStats>
            <div>
                {course.modules.map((module: LearningTypes.IModule) => {
                    const slugifiedname = slugify.toSlug(module.title);
                    return (
                        <ModuleSummary
                          key={module.id}
                          module={module}
                          courseId={Number(courseId)}
                          onDeleteModule={onDeleteModule}
                          onUpdateModule={onUpdateModule}
                          existingModuleTitles={existingModuleTitles}
                          isSaving={isSavingModule}
                        >
                            <Buttons.LinkButton
                              to={`/learning/courses/${courseId}/modules/${module.id}/${slugifiedname}/quizzes/new`}
                              buttonType="secondary"
                            >
                                + Add Quiz
                            </Buttons.LinkButton>
                            <Buttons.LinkButton
                              to={`/learning/courses/${courseId}/modules/${module.id}/${slugifiedname}/lessons/new`}
                            >+ Add Lesson
                            </Buttons.LinkButton>
                        </ModuleSummary>
                    );
                })}
            </div>
            <Buttons.Button onClick={openModuleModal}>Add Module</Buttons.Button>
        </S.CourseContainer>
    );
};

export default Course;
