import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router';
import Spinner from '@apps/common-ui/src/Spinner';
import { RootState } from '../../state/store';
import { useActionLoader } from '../../hooks/useActionLoader';
import LoginForm from '../../components/auth/Login';
import { Buttons, Sidebar, SidebarDivider, SidebarNavLink, FullScreenPage } from '@apps/common-ui';
import PatientsList from '../../pages/PatientsList';
import Patient from '../../pages/Patient';
import PatientSummary from '../../pages/Patient/components/PatientSummary';
import EditPatient from '../../pages/Patient/components/EditPatient';
import ActionPlansList from '../../pages/ActionPlansList';
import ActionPlan from '../../components/ActionPlan';
import { ReactComponent as Logo } from '../../assets/BsLogoWhite.svg';
import { loginUser, logoutUser } from '../../state/reducers/session/actions';
import ExerciseList from '../../pages/ExerciseList';
import ExercisePlan from '../../pages/ExercisePlan';
import Exercise from '../../pages/ExercisePlan/components/Exercise';
import CreateExercise from '../../pages/CreateExercise';
import useInitializeApp from '../../hooks/useInitializeApp';
import ExerciseReporting from '../../pages/ExerciseReporting';
import { ExerciseContextProvider } from '../../pages/ExercisePlan/state/ExerciseContext';
import AssignExercise from '../../pages/ExercisePlan/components/AssignExercise';
import EditExercise from '../../pages/EditExercise';
import EditAssignedExercise from '../../pages/ExercisePlan/components/EditAssignedExercise';
import LearningLibrary from '../../pages/LearningLibrary';
import LearningReporting from '../../pages/LearningReporting';
import Course from '../../pages/Course';
import ManageLesson from '../../pages/ManageLesson';
import ManageQuiz from '../../pages/ManageQuiz';
import QuizHistory from '../../pages/LearningReporting/components/QuizHistory';
import { SidebarLinksContainer } from '@apps/common-ui/src/Sidebar/index.styles';
import RMTReporting from '../../pages/ExerciseReporting/RMTReporting';
import TimeTracking from '../../pages/TimeTracking';

function ProtectedRoute({ children }: { children: React.ReactNode }) {
    const { loggedIn } = useSelector((state: RootState) => state.session);
    // If the user is logged in, show the child components
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return loggedIn ? <>{children}</> : <Navigate to="/login" replace />;
}

function AppRouter() {
    const { loggedIn } = useSelector((state: RootState) => state.session);
    const { callAction: login, done, loading } = useActionLoader(loginUser, { hideToast: true });
    const { callAction: logout } = useActionLoader(logoutUser);
    useInitializeApp();

    useEffect(() => {
        if (!done && !loading) {
            login();
        }
    }, [done, loading]);

    return (
        !done
            ? <Spinner />
            : (
                <>
                    {loggedIn && (
                    <Sidebar>
                        <SidebarLinksContainer style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Logo />
                            <SidebarNavLink to="/patients?page=1">Patients</SidebarNavLink>
                            <SidebarNavLink to="/exercises?page=1">Exercise Library</SidebarNavLink>
                            <SidebarNavLink to="/learning">Learning Library</SidebarNavLink>
                            <SidebarDivider />
                            <Buttons.Button onClick={logout} type="button">Logout</Buttons.Button>
                        </SidebarLinksContainer>
                    </Sidebar>
                    )}
                    <main className="main-content">
                        <Routes>
                            <Route path="/login" element={<LoginForm />} />
                            <Route path="/" element={<ProtectedRoute><PatientsList /></ProtectedRoute>} />
                            <Route path="/patients" element={<ProtectedRoute><PatientsList /></ProtectedRoute>} />
                            <Route path="/patients/:patientId" element={<ProtectedRoute><Patient /></ProtectedRoute>}>
                                <Route index element={<ProtectedRoute><PatientSummary /></ProtectedRoute>} />
                                <Route path="edit" element={<ProtectedRoute><EditPatient /></ProtectedRoute>} />
                                <Route path="action-plans" element={<ProtectedRoute><ActionPlansList /></ProtectedRoute>} />
                                <Route path="action-plans/:actionPlanVersion" element={<ProtectedRoute><ActionPlan /></ProtectedRoute>} />
                                <Route path="action-plans/new" element={<ProtectedRoute><ActionPlan /></ProtectedRoute>} />
                                <Route path="exercise-plan" element={<ProtectedRoute><ExerciseContextProvider><ExercisePlan /></ExerciseContextProvider></ProtectedRoute>} />
                                <Route path="time-tracking" element={<ProtectedRoute><TimeTracking /></ProtectedRoute>} />
                                <Route
                                  path="exercise-reporting"
                                  element={(
                                      <ProtectedRoute>
                                          <ExerciseContextProvider>
                                              <ExerciseReporting rmt={false} />
                                          </ExerciseContextProvider>
                                      </ProtectedRoute>
                                  )}
                                />
                                <Route
                                  path="rmt-reporting"
                                  element={(
                                      <ProtectedRoute>
                                          <ExerciseContextProvider>
                                              <ExerciseReporting rmt />
                                          </ExerciseContextProvider>
                                      </ProtectedRoute>
                                  )}
                                />
                                <Route
                                  path="exercise-plan/assign-exercise/:day"
                                  element={(
                                      <ProtectedRoute>
                                          <ExerciseContextProvider>
                                              <AssignExercise />
                                          </ExerciseContextProvider>
                                      </ProtectedRoute>
                                  )}
                                />
                                <Route
                                  path="exercise-plan/assigned-exercise/edit"
                                  element={(
                                      <ProtectedRoute>
                                          <ExerciseContextProvider>
                                              <EditAssignedExercise />
                                          </ExerciseContextProvider>
                                      </ProtectedRoute>
                                  )}
                                />
                                <Route path="learning-reporting" element={<ProtectedRoute><LearningReporting /></ProtectedRoute>} />
                                <Route path="learning-reporting/quiz/:quizId" element={<ProtectedRoute><QuizHistory /></ProtectedRoute>} />
                            </Route>
                            <Route path="/exercises" element={<ProtectedRoute><ExerciseList hasAction={false} /></ProtectedRoute>} />
                            <Route path="/exercises/:exerciseId" element={<ProtectedRoute><EditExercise /></ProtectedRoute>} />
                            <Route path="/exercises/new" element={<ProtectedRoute><CreateExercise /></ProtectedRoute>} />
                            <Route path="/learning" element={<ProtectedRoute><LearningLibrary /></ProtectedRoute>} />
                            <Route path="/learning/courses/:courseId" element={<ProtectedRoute><Course /></ProtectedRoute>} />
                            <Route path="/learning/courses/:courseId/modules/:moduleId/:moduleName/lessons/new" element={<ProtectedRoute><ManageLesson /></ProtectedRoute>} />
                            <Route path="/learning/courses/:courseId/modules/:moduleId/:moduleName/lessons/:lessonId" element={<ProtectedRoute><ManageLesson /></ProtectedRoute>} />
                            <Route path="/learning/courses/:courseId/modules/:moduleId/:moduleName/quizzes/new" element={<ProtectedRoute><ManageQuiz /></ProtectedRoute>} />
                            <Route path="/learning/courses/:courseId/modules/:moduleId/:moduleName/quizzes/:quizId" element={<ProtectedRoute><ManageQuiz /></ProtectedRoute>} />
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </main>
                </>
            )
    );
}

export default AppRouter;
