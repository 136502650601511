import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const RadioContainer = styled.div`
  display: flex;
  padding: 10px 0;
  margin: 0 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin: 0 10px;
`;

export const StatusContainer = styled.div<{status: 'success' | 'info' | 'danger'}>`
  height: 20%;
  width: 60%;
  justify-content: center;
  align-items: center;
  align-self: center;
  display: flex;
  background-color: ${(props) => {
        switch (props.status) {
            case 'success':
                return props.theme.success.dark;
            case 'info':
                return props.theme.primary.dark;
            case 'danger':
                return props.theme.danger.dark;
            default:
                return props.theme.primary.dark;
        }
    }
};
  color: ${(props) => props.theme.shades.white};
  border-radius: 5px;
  padding: 10px;
`;

export const StatusText = styled.p`
  margin: 0;
`;
