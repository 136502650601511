import React, { PropsWithChildren } from 'react';
import * as S from '../index.styles';
import { Buttons } from '@apps/common-ui';
import { format } from 'date-fns';
import { ReactComponent as PlaceholderIcon } from '../../../../../assets/Placeholder.svg';
import { LearningTypes } from '@apps/common-utilities';
import { ISubmoduleSubmission } from '@apps/common-utilities/src/types/learningTypes';

type Props = {
    moduleSubmission: LearningTypes.IModuleSubmission,
}

const ModuleSummarySubmission = ({ moduleSubmission, children }: PropsWithChildren<Props>) => (
    <S.ModuleSummaryContainer>
        <S.ModuleSummaryHeaderContainer>
            <img src={moduleSubmission.thumbnailUrl || ''} alt="Module Thumbnail" width="80" height="80" />
            <S.ModuleSummaryHeader>
                <h3>{moduleSubmission.title}</h3>
                <S.CompletionDate>Target Date: {format(new Date(moduleSubmission.targetCompletionDate), 'do LLLL yyyy')}</S.CompletionDate>
            </S.ModuleSummaryHeader>
        </S.ModuleSummaryHeaderContainer>
        <hr />
        <S.SubmoduleList>
            {moduleSubmission.subModules.map((submodule: ISubmoduleSubmission) => {
                    let link = '';
                    if (submodule.type === LearningTypes.SubModuleType.QUIZ) {
                        link = `quiz/${submodule.id}`; // quiz id
                    }
                    return (
                        <>
                            <S.SubmoduleListItem>
                                <h4>{submodule.title}</h4>
                                <S.SubmissionResults>
                                    {submodule.type === LearningTypes.SubModuleType.QUIZ && submodule.result && (
                                        <S.QuizScore>{submodule.result}</S.QuizScore>
                                    )}
                                    {submodule.type === LearningTypes.SubModuleType.LESSON && submodule.result && (
                                        <S.SubmissionResultText>{submodule.result.toLowerCase()}</S.SubmissionResultText>
                                    )}
                                    {submodule.type === LearningTypes.SubModuleType.QUIZ && submodule.completedAt
                                    && (
                                        <Buttons.LinkButton
                                          to={link}
                                          buttonType="tertiary"
                                        >
                                            View Score
                                        </Buttons.LinkButton>
                                    )}
                                    {submodule.completedAt
                                    && (
                                        <S.SubmissionStatus status="success">
                                            Completed on {format(new Date(submodule.completedAt), 'do LLLL yyyy')}
                                        </S.SubmissionStatus>
                                    )}
                                </S.SubmissionResults>
                            </S.SubmoduleListItem>
                            <hr />
                        </>
                );
            })}
        </S.SubmoduleList>
        <S.ModuleActions>
            {children}
        </S.ModuleActions>
    </S.ModuleSummaryContainer>
);

export default ModuleSummarySubmission;
