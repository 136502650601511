import { createSlice } from '@reduxjs/toolkit';
import { LearningTypes } from '@apps/common-utilities';
import { Routes } from '../../../api/Routes';
import { AppDispatch } from '../../store';
import { TenantApi } from '../../../api/CoachingAPI';

export interface LearningState {
    courses: LearningTypes.ICourse[];
}

const initialState: LearningState = {
    courses: []
};

const learningSlice = createSlice({
    name: 'learning',
    initialState,
    reducers: {
        setCourses: (state, { payload }: {payload: { courses: LearningTypes.ICourse[] }}) => {
            state.courses = payload.courses;
        },
        addCourse: (state, { payload }: {payload: { course: LearningTypes.ICourse }}) => {
            state.courses.push(payload.course);
        },
        setCourse: (state, { payload }: {payload: { course: LearningTypes.ICourse }}) => {
            const courseIndex = state.courses.findIndex(course => course.courseId === payload.course.courseId);
            state.courses[courseIndex] = payload.course;
        }
    }
});

export const {
    setCourses,
    addCourse,
    setCourse
} = learningSlice.actions;

export default learningSlice.reducer;

export const fetchCourses = () => async (dispatch: AppDispatch) => {
    const courses = await TenantApi.get(`/${Routes.coaches}/${Routes.courses}`);
    dispatch(setCourses({ courses }));
};

export const createCourse = ({ course }: { course: LearningTypes.ICreateCourse}) => async (dispatch: AppDispatch) => {
    const createdCourse = await TenantApi.post(`/${Routes.coaches}/${Routes.courses}`, course);
    dispatch(addCourse({ course: createdCourse }));
};

export const updateCourse = ({ course }: { course: LearningTypes.IEditCourse}) => async (dispatch: AppDispatch) => {
    const updatedCourse = await TenantApi.put(`/${Routes.coaches}/${Routes.courses}/${course.courseId}`, course);
    dispatch(setCourse({ course: updatedCourse }));
};

export const deleteCourse = ({ courseId }: { courseId: string }) => async (dispatch: AppDispatch) => {
    await TenantApi.delete(`/${Routes.coaches}/${Routes.courses}/${courseId}`);
    dispatch(fetchCourses());
};
