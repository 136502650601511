import styled from 'styled-components';

export const CourseContainer = styled.div`
    padding: 30px 50px;
    margin: 0 auto;
`;

export const CourseNameContainer = styled.div`
    display: flex;
    flex-direction: column;

    h2 {
        margin: 0;
    }
`;

export const ManageButtons = styled.div`
    display: flex;
`;

export const LastUpdated = styled.h4`
    color: ${props => props.theme.shades.grey};
`;

export const CourseStats = styled.div`
    display: flex;
`;

export const CourseStat = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
        margin-right: 50px;
    }

    h4 {
        margin: 5px 0;
    }
`;
