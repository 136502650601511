import styled from 'styled-components';

export const LessonForm = styled.form`
    max-width: 1200px;
    width: 100%;
    padding: 30px 50px;
`;

export const FormColumns = styled.div`
    display: flex;
    align-items: stretch;
    margin-bottom: 40px;
`;

export const FormColumn = styled.div`
    width: 50%;

    iframe {
        display: auto;
    }
`;

export const Titles = styled.div`
    h2, h4 {
        margin: 0;
    }

    h4 {
        color: ${props => props.theme.shades.grey};
    }
`;

export const LessonContentContainer = styled.div`
    margin-bottom: 50px;
`;

export const ModuleName = styled.span`
    text-transform: capitalize;
    margin-left: 10px;
`;

export const LessonContentHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
        font-size: 14px;
        color: ${props => props.theme.shades.grey};
    }
`;

export const WordCount = styled.span<{ isOver: boolean }>`
    font-size: 14px;
    color: ${props => props.isOver ? props.theme.danger.medium : props.theme.success.medium};
`;

export const ButtonsGroup = styled.div`
    display: flex;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LessonPreviewContainer = styled.div`
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
`;

export const LessonPreviewContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    * {
        font-family: inherit !important;
        margin-top: 0px;
        margin-bottom: 5px;
    }


    figure {
        margin: 0 !important;
    }
`;
