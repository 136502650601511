import styled from 'styled-components';

export const CourseForm = styled.form`
    width: 100%;
    height: 100%;
    display: flex
    flex-direction: column;
`;

export const FormContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
`;

export const CourseNames = styled.div`
    width: 60%;
`;

export const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
`;

export const InnerActions = styled.div`
    display: flex;
    align-items: center;
`;
