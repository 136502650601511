import { Modal, Inputs, Buttons, useConfirmDialog } from '@apps/common-ui';
import { LearningTypes } from '@apps/common-utilities';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as S from './index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
    onDismiss: () => void;
    onSave: (data: LearningTypes.ICreateCourse) => void;
    onDelete?: () => void;
    defaultValues?: LearningTypes.ICreateCourse;
    existingInternalTitles: string[];
    isSaving?: boolean;
}

const ManageCourseModal = ({ onDismiss, onSave, onDelete, defaultValues, existingInternalTitles, isSaving }: Props) => {
    const [uploadLoading, setUploadLoading] = useState(false);
    const { register, formState: { errors }, handleSubmit, setError } = useForm({
        defaultValues: defaultValues || {}
    });
    const { ConfirmationDialog, confirm } = useConfirmDialog({
        message: 'Are you sure you want to delete this course? This cannot be undone.'
    });

    const onSubmit = async (data: any) => {
        setUploadLoading(true);
        const isUpdating = defaultValues
        && defaultValues.internalTitle.toLowerCase() === data.internalTitle.toLowerCase();
        if (!isUpdating && existingInternalTitles.includes(data.internalTitle.toLowerCase())) {
            setError('internalTitle', {
                type: 'duplicate',
                message: 'Internal title already exists',
            });
            setUploadLoading(false);
            return;
        }
        if (data) {
            await onSave(data);
        }
    };

    const handleDelete = (e: any) => {
        e.preventDefault();
        if (onDelete) {
            confirm().then(() => {
                onDelete();
            });
        }
    };

    return (
        <Modal showModal dismissModal={onDismiss} title="Create/edit course">
            <ConfirmationDialog />
            <S.CourseForm onSubmit={handleSubmit(onSubmit)}>
                <S.FormContent>
                    <S.CourseNames>
                        <Inputs.InputContainer error={errors.internalTitle}>
                            <Inputs.Label>Internal Course Name</Inputs.Label>
                            <Inputs.Input
                              id="internalTitle"
                              placeholder="Internal course name"
                              {...register('internalTitle', { required: true })}
                            />
                        </Inputs.InputContainer>
                        <Inputs.InputContainer error={errors.userTitle}>
                            <Inputs.Label>User-facing Course Name</Inputs.Label>
                            <Inputs.Input
                              id="userTitle"
                              placeholder="Course name"
                              {...register('userTitle', { required: true })}
                            />
                        </Inputs.InputContainer>
                    </S.CourseNames>
                    <div>
                        <Inputs.InputContainer error={errors.courseWeekLength}>
                            <Inputs.Label>Course length (weeks)</Inputs.Label>
                            <Inputs.Input
                              id="courseWeekLength"
                              type="number"
                              {...register('courseWeekLength', { valueAsNumber: true, required: true, min: 1 })}
                              inputSize={Inputs.InputSize.extraSmall}
                              min={1}
                              defaultValue={10}
                            />
                        </Inputs.InputContainer>
                    </div>
                </S.FormContent>
                <S.Actions>
                    <S.InnerActions>
                        <Buttons.Button type="submit" disabled={isSaving || uploadLoading}>
                            {uploadLoading ? <FontAwesomeIcon icon={faSpinner as IconProp} spin /> : 'Save'}
                        </Buttons.Button>
                        <Buttons.Button buttonType="tertiary" onClick={onDismiss}>Cancel</Buttons.Button>
                    </S.InnerActions>
                    {onDelete && <Buttons.Button buttonStyle="danger" buttonType="tertiary" onClick={handleDelete}>Delete</Buttons.Button>}
                </S.Actions>
            </S.CourseForm>
        </Modal>
    );
};

export default ManageCourseModal;
