import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchCoaches, fetchPatients } from '../state/reducers/coaching';
import { fetchCourses } from '../state/reducers/learning';
import { fetchExercises } from '../state/reducers/physicalTherapy';
import { RootState } from '../state/store';
import { useActionLoader } from './useActionLoader';

const useInitializeApp = () => {
    const { user } = useSelector((state: RootState) => state.session);
    const { callAction: getPatients, done: patientsDone, loading: patientsLoading } = useActionLoader(fetchPatients);
    const { callAction: getCoaches, done: coachesDone, loading: coachesLoading } = useActionLoader(fetchCoaches);
    const { callAction: getExercises, done: exercisesDone, loading: exercisesLoading } = useActionLoader(fetchExercises);
    const { callAction: getCourses, done: coursesDone, loading: coursesLoading } = useActionLoader(fetchCourses);

    useEffect(() => {
        if (!user) return;
        if (!patientsDone && !patientsLoading) {
            getPatients({ page: 0, size: 10 });
        }
        if (!coachesDone && !coachesLoading) {
            getCoaches();
        }
        if (!exercisesDone && !exercisesLoading) {
            getExercises();
        }
        if (!coursesDone && !coursesLoading) {
            getCourses();
        }
    }, [user, patientsDone, coachesDone, coursesDone]);
};

export default useInitializeApp;
