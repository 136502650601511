import { Buttons, useModal } from '@apps/common-ui';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { PageHeader } from '../../components/common/commonStyled';
import * as S from './index.styles';
import { NaiveDate, monthDateYear } from '../../utils/dateUtils';
import CreateCourseModal from '../../components/ManageCourseModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { LearningTypes } from '@apps/common-utilities';
import { useActionLoader } from '../../hooks/useActionLoader';
import { createCourse, fetchCourses } from '../../state/reducers/learning';
import toast from 'react-hot-toast';

const columns = [
    {
        name: 'Name',
        selector: (row: LearningTypes.ICourse) => row.updatedAt,
        cell: (row: LearningTypes.ICourse) => (
            <S.CourseName>
                <h3>{row.internalTitle}</h3>
                <span>{row.updatedAt && monthDateYear(new NaiveDate(row.updatedAt))}</span>
            </S.CourseName>
        )
    },
    {
        name: 'Assigned to',
        selector: (row: LearningTypes.ICourse) => row.numberOfAssignments,
    },
    {
        name: 'Modules',
        selector: (row: LearningTypes.ICourse) => row.numberOfModules,
    },
    {
        name: 'Lessons',
        selector: (row: LearningTypes.ICourse) => row.numberOfLessons,
    },
    {
        name: 'Quizzes',
        selector: (row: LearningTypes.ICourse) => row.numberOfQuizzes,
    },
    {
        name: '',
        cell: (row: LearningTypes.ICourse) => (
            <Buttons.LinkButton
              buttonType="tertiary"
              to={`/learning/courses/${row.courseId}`}
            >
                Manage
            </Buttons.LinkButton>
        )
    }
];

const LearningLibrary = () => {
    const { isOpen, openModal, closeModal } = useModal();
    const { courses } = useSelector((state: RootState) => state.learning);
    const [isSaving, setIsSaving] = useState(false);
    const { callAction: getCourses, done: coursesFetched } = useActionLoader(fetchCourses);
    const { callAction: addNewCourse, done } = useActionLoader(createCourse, {
        successMessage: 'Course created successfully',
        errorMessage: 'Failed to create course'
    });
    let existingInternalTitles: string[] = [];

    const onCreateCourse = async (data: LearningTypes.ICreateCourse) => {
        if (!data.internalTitle || !data.userTitle || !data.courseWeekLength) {
            toast.error('All fields are required');
            return;
        }
        setIsSaving(true);
        try {
            await addNewCourse({ course: data });
        } catch (error) {
            toast.error('Failed to create course');
        }
    };

    useEffect(() => {
        if (done) {
            closeModal();
            setIsSaving(false);
        }
    }, [done]);

    useEffect(() => {
        if (!coursesFetched) {
            getCourses();
        }
    }, [coursesFetched]);

    if (courses) {
        existingInternalTitles = courses.map(course => course.internalTitle.toLowerCase());
    }

    return (
        <S.CourseListContainer>
            {isOpen && (
            <CreateCourseModal
              onDismiss={() => {
                closeModal();
                setIsSaving(false);
              }}
              onSave={onCreateCourse}
              existingInternalTitles={existingInternalTitles}
              isSaving={isSaving}
            />
            )}
            <PageHeader>
                <h2>Learning Library</h2>
                <Buttons.Button buttonType="tertiary" onClick={openModal}>+ Add New Course</Buttons.Button>
            </PageHeader>
            <DataTable
              columns={columns}
              data={courses}
              highlightOnHover
              pagination
              striped
              paginationDefaultPage={1}
              paginationPerPage={60}
            />
        </S.CourseListContainer>
    );
};

export default LearningLibrary;
